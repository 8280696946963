<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Aportante Afiliado</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-autocomplete
                label="Aportante"
                v-model="contributor"
                required
                :items="contributors"
                item-text="name"
                item-value="_id"
                :rules="contributorRules"
                @change="getBranchs"
            ></v-autocomplete>

            <v-autocomplete
                label="Sucursal del Aportante"
                v-model="branch"
                required
                :items="branchs"
                item-text="name"
                item-value="_id"
                :rules="branchRules"
            ></v-autocomplete>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
import { titleCase } from "@/modules/tools";

const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            contributor: '',
            contributorRules: [
                v => !!v || 'El Aportante es requerido',
            ],
            contributors: [],
            branch: '',
            branchRules: [
                v => !!v || 'La Sucursal es requerida',
            ],
            branchs: [],
        }
    },
    methods: {
        async getBranchs() {
            const branchData = await store.getData("branch");
            const cityData = await store.getData("city");
            const stateData = await store.getData("state");
            this.branchs = [];
            branchData.forEach(branch => {
                if (branch.contributor === this.contributor) {
                    branch.name = '';
                    cityData.forEach(city => {
                        if (city._id === branch.city) {
                            branch.name += titleCase(city.name);
                        }
                    });
                    stateData.forEach(state => {
                        if (state._id === branch.state) {
                            branch.name += ' - '+titleCase(state.name);
                        }
                    });
                    branch.name += ' | '+branch.address;
                    this.branchs.push(branch);
                }
            });
        },
        async getContributor() {
            const contributorData = await store.getData("contributor");
            const branch = await store.getData("branch", this.branch);
            contributorData.forEach(contributor => {
                if (contributor._id === branch.contributor) {
                    this.contributor = contributor._id;
                    this.getBranchs();
                }
            });
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.contributor = value.contributor;
                        this.branch = value.branch;
                        this.getContributor();
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 1,
            valid: this.valid,
            data: {
                branch: this.branch
            }
        })
    },
    async mounted() {
        const contributorData = await store.getData("contributor");
        contributorData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        contributorData.forEach(contributor => {
            if (contributor.office === this.$route.params._id) {
                this.contributors.push(contributor); 
            }
        });
        this.mountedFinish = true;
    }
}
</script>