<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Afiliación</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
                v-model="affiliationDate"
                :rules="affiliationDateRules"
                type="date"
                label="Fecha de la Afiliación"
                required
            ></v-text-field>

            <v-text-field
                v-model="supportFee"
                type="number"
                label="Cuota de Sostenimiento"
                prefix="$"
                required
            ></v-text-field>

            <v-autocomplete
                label="EPS"
                v-model="EPS"
                required
                :items="EPSS"
                item-text="name"
                item-value="_id"
            ></v-autocomplete>

            <v-autocomplete
                label="Fondo de Pensión"
                v-model="AFP"
                required
                :items="AFPS"
                item-text="name"
                item-value="_id"
            ></v-autocomplete>

            <v-autocomplete
                label="Caja de Compensación"
                v-model="CCF"
                required
                :items="CCFS"
                item-text="name"
                item-value="_id"
            ></v-autocomplete>

            <v-select
                v-model="ARLRate"
                :items="ARLRates"
                label="Tasa de Riesgo (ARL)"
                required
            ></v-select>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
import { dateToTimestamp, TimestampToDate } from "@/modules/tools";
const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            affiliationDate: '',
            affiliationDateRules: [
                v => !!v || 'La Fecha de Afiliación es requerida',
            ],
            supportFee: '',
            supportFeeMin: 0,
            supportFeeMax: 1,
            EPS: '',
            EPSS: [],
            AFP: '',
            AFPS: [],
            CCF: '',
            CCFS: [],
            ARLRate: '',
            ARLRates: [],
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                       console.log(value);
                        if (value.ARLRate == null) {
                            value.ARLRate = 'No Especificado';
                        }
                        this.affiliationDate = TimestampToDate(value.affiliationDate);
                        this.supportFee = value.supportFee;
                        this.EPS = value.EPS;
                        this.AFP = value.AFP;
                        this.CCF = value.CCF;
                        this.ARLRate = value.ARLRate;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        let ARLRate = null;
        if (this.supportFee == '') {
            this.supportFee = 0;
        }
        if (this.EPS == '') {
            this.EPS = null;
        }
        if (this.AFP == '') {
            this.AFP = null;
        }
        if (this.CCF == '') {
            this.CCF = null;
        }
        if (this.ARLRate != 'No Especificado' && this.ARLRate != '') {
            ARLRate = this.ARLRate;
        }
        this.$emit('valid', {
            step: 4,
            valid: this.valid,
            data: {
                affiliationDate: dateToTimestamp(this.affiliationDate),
                supportFee: this.supportFee,
                EPS: this.EPS,
                AFP: this.AFP,
                CCF: this.CCF,
                ARLRate: ARLRate
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        const EPSData = await store.getData("EPS");
        const AFPData = await store.getData("AFP");
        const CCFData = await store.getData("CCF");
        schemaData.forEach(schema => {
            if (schema.store === "affiliate") {
                this.supportFeeMin = schema.data.supportFee.min;
                this.supportFeeMax = schema.data.supportFee.max;
                schema.data.ARLRate.enum.unshift('No Especificado');
                this.ARLRates = schema.data.ARLRate.enum;
            }
        });
        EPSData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        EPSData.unshift({
            name: 'No Especificado',
            _id: null
        });
        this.EPSS = EPSData;
        AFPData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        AFPData.unshift({
            name: 'No Especificado',
            _id: null
        });
        this.AFPS = AFPData;
        CCFData.sort(function(a, b) {
            return a.name.localeCompare(b.name);
        });
        CCFData.unshift({
            name: 'No Especificado',
            _id: null
        });
        this.CCFS = CCFData;
        this.mountedFinish = true;
    },
}
</script>