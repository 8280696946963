<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Datos Básicos</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-select
                v-model="IDType"
                :items="IDTypes"
                :rules="IDTypeRules"
                label="Tipo de Documento"
                required
            ></v-select>

            <v-text-field
                v-model="ID"
                :rules="IDRules"
                type="number"
                label="Número de Documento"
                required
            ></v-text-field>

            <v-text-field
                v-model="name"
                :counter="nameMax"
                :maxlength="nameMax"
                :rules="nameRules"
                label="Nombres y Apellidos"
                required
            ></v-text-field>

            <v-checkbox
                v-model="minimumSalary"
                :label="'¿Salario Mínimo?'"
            ></v-checkbox>

            <v-text-field
                v-model="currentMinimumSalary"
                label="Salario"
                disabled
                v-if="minimumSalary"
            ></v-text-field>

            <v-text-field
                v-model="salary"
                :rules="salaryRules"
                type="number"
                label="Salario"
                prefix="$"
                v-else
                required
            ></v-text-field>

            <v-text-field
                v-model="position"
                :counter="positionMax"
                :maxlength="positionMax"
                :rules="positionRules"
                label="Cargo u Ocupación"
                required
            ></v-text-field>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
const store = new Storage();
import { prettyCurrency } from "@/modules/tools";

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            currentMinimumSalary: '',
            IDType: '',
            IDTypes: [],
            IDTypeRules: [
                v => !!v || 'El Tipo de Documento es requerido',
            ],
            ID: '',
            IDMin: 0,
            IDMax: 1,
            IDRules: [
                v => !!v || 'El Número de Documento es requerido',
                v => (v && v >= this.IDMin) || `El Número de Documento debe ser un número superior a ${this.IDMin}`,
                v => (v && v <= this.IDMax) || `El Número de Documento debe ser un número inferior a ${this.IDMax}`,
            ],
            name: '',
            nameMin: 0,
            nameMax: 1,
            nameRules: [
                v => !!v || 'El nombre es requerido',
                v => (v && v.length >= this.nameMin) || `El nombre debe tener al menos ${this.nameMin} letras`,
                v => (v && v.length <= this.nameMax) || `El nombre no debe superar las ${this.nameMax} letras`,
            ],
            minimumSalary: true,
            salary: 0,
            salaryMin: 0,
            salaryMax: 1,
            salaryRules: [
                v => !!v || 'El Salario es requerido',
                v => (v && v >= this.salaryMin) || `El Salario debe ser un número superior a ${this.salaryMin}`,
                v => (v && v <= this.salaryMax) || `El Salario debe ser un número inferior a ${this.salaryMax}`,
            ],
            position: '',
            positionMin: 0,
            positionMax: 1,
            positionRules: [
                v => !!v || 'El Cargo u Ocupacíon es requerido',
                v => (v && v.length >= this.positionMin) || `El Cargo u Ocupacíon debe tener al menos ${this.positionMin} letras`,
                v => (v && v.length <= this.positionMax) || `El Cargo u Ocupacíon no debe superar las ${this.positionMax} letras`,
            ],
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.IDType = value.IDType;
                        this.ID = value.ID;
                        this.name = value.name;
                        this.minimumSalary = value.minimumSalary;
                        this.salary = value.salary;
                        this.position = value.position;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 2,
            valid: this.valid,
            data: {
                IDType: this.IDType,
                ID: parseInt(this.ID),
                name: this.name,
                minimumSalary: this.minimumSalary,
                salary: parseInt(this.salary),
                position: this.position
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        const country = await store.getData("country", localStorage.getItem('user.country'));
        schemaData.forEach(schema => {
            if (schema.store === "affiliate") {
                this.IDTypes = schema.data.IDType.enum;
                this.IDMin = schema.data.ID.min;
                this.IDMax = schema.data.ID.max;
                this.nameMin = schema.data.name.min;
                this.nameMax = schema.data.name.max;
                this.salaryMin = schema.data.salary.min;
                this.salaryMax = schema.data.salary.max;
                this.positionMin = schema.data.position.min;
                this.positionMax = schema.data.position.max;
            }
        });
        this.currentMinimumSalary = await prettyCurrency(country.minimumSalary);
        this.mountedFinish = true;
    },
}
</script>