<template>
    <v-card
        class="mx-auto mb-12"
        style="background-color: unset;"
        elevation="0"
    >
        <h2 class="text-center">Datos Adicionales</h2>
        <v-form
            class="px-5"
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-text-field
                v-model="commercialAgreement"
                :counter="commercialAgreementMax"
                :maxlength="commercialAgreementMax"
                label="Convenio Comercial"
            ></v-text-field>

            <v-text-field
                v-model="IPS"
                :counter="IPSMax"
                :maxlength="IPSMax"
                label="IPS del Afiliado"
            ></v-text-field>

            <v-text-field
                v-model="beneficiaries"
                :counter="beneficiariesMax"
                :maxlength="beneficiariesMax"
                label="Beneficiarios"
            ></v-text-field>

            <v-textarea
                v-model="commentary"
                :counter="commentaryMax"
                :maxlength="commentaryMax"
                label="Anotaciones"
            ></v-textarea>
        </v-form>
    </v-card>
</template>

<script>
import Storage from "@/modules/storage";
import { titleCase } from "@/modules/tools";
const store = new Storage();

export default {
    data() {
        return {
            mountedFinish: false,
            valid: false,
            commercialAgreement: '',
            commercialAgreementMax: 1,
            IPS: '',
            IPSMax: 1,
            beneficiaries: '',
            beneficiariesMax: 1,
            commentary: '',
            commentaryMax: 1,
        }
    },
    props: ['validate', 'default'],
    watch: {
        validate: function(value) {
            if (value) {
                this.$refs.form.validate();
            }
        },
        default: function(value) {
           if (value) {
               const checkMount = setInterval(() => {
                   if (this.mountedFinish) {
                        this.commercialAgreement = value.commercialAgreement;
                        this.IPS = value.IPS;
                        this.beneficiaries = value.beneficiaries;
                        this.commentary = value.commentary;
                        clearInterval(checkMount);
                   }
               }, 100);
            } 
        }
    },
    updated() {
        this.$emit('valid', {
            step: 5,
            valid: this.valid,
            data: {
                commercialAgreement: this.commercialAgreement,
                IPS: this.IPS,
                beneficiaries: this.beneficiaries,
                commentary: this.commentary
            }
        })
    },
    async mounted() {
        const schemaData = await store.getData("schema");
        schemaData.forEach(schema => {
            if (schema.store === "affiliate") {
                this.commercialAgreementMax = schema.data.commercialAgreement.max;
                this.IPSMax = schema.data.IPS.max;
                this.beneficiariesMax = schema.data.beneficiaries.max;
                this.commentaryMax = schema.data.commentary.max;
            }
        });
        this.mountedFinish = true;
    },
}
</script>